import styled from "styled-components/macro";
import { H6Thin } from "../../typography";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const InnerContainer = styled.div`
  width: 464px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 500px) {
    width: 95%;
  }
`;

export const SubTitle = styled(H6Thin)`
  color: ${({ theme }) => theme.gray9};
  margin-top: 24px;
  margin-bottom: 80px;
`;

export const InputSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;

  img {
    :last-of-type {
      transition: 0.2s ease-in-out;
      cursor: pointer;
      margin-left: 24px;

      :hover {
        opacity: 0.6;
      }
    }
  }
`;

export const Link = styled.div`
  width: 100%;
  margin-top: 54px;
`;
