import styled from "styled-components";
import { H6Thin, ParagraphRegular } from "../../typography";

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    :hover {
      opacity: 0.6;
    }
  }
`;

export const HeaderTitle = styled(H6Thin)`
  background: ${({ theme }) => theme.gradient8};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 8px;
    margin-top: 8px;
  }
`;

export const TitleLabel = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.white};
  margin-bottom: 24px;
  margin-top: 32px;
`;

export const InputContainers = styled.div`
  display: flex;
  align-items: center;

  main {
    margin-left: 16px;

    :first-of-type {
      margin-left: 0px;
    }
  }
`;

export const MinutesInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

export const MinutesInputContain = styled.div`
  width: 121px;
`;

export const MinuteInputText = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.white};
  margin-left: 16px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;

  p {
    margin: 0 !important;
  }

  img {
    margin-top: 0 !important;
  }
`;
