import styled from "styled-components/macro";

const BaseButton = styled.button`
  border: none;
  border-radius: 8px;
  padding: 0px 16px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: keep-all;

  &:hover {
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.gray23};
    color: ${({ theme }) => theme.gray18};
  }
`;

export const PrimaryContainer = styled(BaseButton)`
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.black};
  word-break: keep-all;
`;

export const SecondaryContainer = styled(BaseButton)`
  background-color: ${({ theme }) => theme.gray23};
  color: ${({ theme }) => theme.primary5};
  word-break: keep-all;
`;

export const BigBtnContainer = styled(BaseButton)`
  width: 100%;
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.black};
  word-break: keep-all;
`;

export const IconBtn = styled(BaseButton)`
  background-color: ${({ theme }) => theme.gray23};
  color: ${({ theme }) => theme.primary5};
`;
