import { Route, Routes } from "react-router-dom";
import { AuthLayout, MainLayout } from "./layouts";
import { routeBuilder } from "./routeBuilder";

export default function AppRouter({ themeToggler }) {
  return (
    <Routes>
      {routeBuilder.map((route, i) => (
        <Route
          key={i}
          path={route.route}
          element={
            route.layout === "user" ? (
              <MainLayout themeToggler={themeToggler}>
                {route.components}
              </MainLayout>
            ) : route.layout === "auth" ? (
              <AuthLayout>{route.components}</AuthLayout>
            ) : (
              "enter valid route"
            )
          }
        />
      ))}
    </Routes>
  );
}
