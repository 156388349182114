import { createGlobalStyle } from "styled-components/macro";

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0px;
        font-family: 'Poppins', sans-serif;
        transition: 0.2s ease-in-out;
    }

    body {
        background-color: ${({ theme }) => theme.gray24};
    }

    h1, h2, h3, h4, h5, h6, p {
        margin: 0px;
    }

`;
