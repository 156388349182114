import styled from "styled-components";

export const Tint = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.mask};
  z-index: 3;
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.gray24};
  width: 464px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  border-radius: 16px;
  padding: 40px 24px;

  @media (max-width: 600px) {
    width: calc(98% - 48px);
  }
`;
