import { useState } from "react";

import { ConnectModal, Navigation } from "../../components";

export const MainLayout = ({ children, themeToggler }) => {
  const [connectModal, setConnectModal] = useState(false);

  const openModal = () => {
    setConnectModal(true);
  };

  return (
    <>
      {connectModal ? (
        <ConnectModal closeModal={() => setConnectModal(false)} />
      ) : null}
      <Navigation themeToggler={themeToggler} connectToWallet={openModal} />
      {children}
    </>
  );
};
