import styled from "styled-components/macro";

export const Container = styled.div`
  width: calc(100% - 34px);
  border: 1px solid ${({ theme }) => theme.gray23};
  height: 64px;
  border-radius: 8px;
  padding: 0px 16px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.gray9};
  position: absolute;
  bottom: 0px;
  height: 100%;
  pointer-events: none;
  font-size: 0.88rem;
  font-weight: 600;

  span {
    position: absolute;
    bottom: 23px;
    transition: 0.3s ease-in-out;
  }
`;

export const InputField = styled.input`
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 0.88rem;
  font-weight: 600;
  color: ${({ theme }) => theme.gray9};

  :focus + ${Label} span {
    transform: translateY(-100%);
    font-size: 0.75rem;
  }
`;

export const EyeContainer = styled.div`
  cursor: pointer;
`;
