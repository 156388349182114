import { TokenSelect } from "../tokenSelect";
import {
  Container,
  Balance,
  Destination,
  Amount,
  LeftContainer,
} from "./style";

export const InputToken = ({
  destination,
  amount,
  balance,
  value,
  onClick,
  tokens,
  onChange,
  handleSelect,
}) => {
  return (
    <Container>
      <LeftContainer>
        <Destination>{destination}</Destination>
        <Amount placeholder="0.0" value={amount} onChange={onChange} />
        <Balance>Balance: {balance || 0.0}</Balance>
      </LeftContainer>

      <TokenSelect
        tokens={tokens}
        value={value}
        onClick={onClick}
        handleSelect={handleSelect}
      />
    </Container>
  );
};
