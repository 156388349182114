import { Tint, Container } from "./style";

export const Modal = ({ closeModal, children }) => {
  return (
    <>
      <Tint onClick={closeModal} />
      <Container>{children}</Container>
    </>
  );
};
