import styled from "styled-components/macro";
import { Caption12, H5Thin, ParagraphRegular, Title2 } from "../../typography";
import { lightTheme } from "../../theme";

export const Container = styled.button`
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: ${({ theme }) => theme.primary5};

  &:hover {
    opacity: 0.6;
  }
`;

export const ValueContainer = styled.button`
  border: none;
  display: flex;
  align-items: center;
  height: 64px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.gray23};
  padding: 0px 10px;
  border-radius: 8px;
  cursor: pointer;

  img {
    filter: ${({ theme }) => theme === lightTheme && "invert(100%)"};
    :last-of-type {
      margin-left: 30px;
    }
  }
`;

export const Value = styled.div`
  max-width: 100px;
  overflow: hidden;
  margin-left: 10px;
  color: ${({ theme }) => theme.white};
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const ImgContainer = styled.div`
  width: 26.67px;
  height: 26.67px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.gray19};
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 24px;

  img {
    position: absolute;
    right: 0;
  }
`;

export const ModalHeaderText = styled(H5Thin)`
  background: ${({ theme }) => theme.gradient8};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const List = styled.div`
  margin-top: 24px;
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
  cursor: pointer;
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;

  aside {
    margin-left: 20px;
  }
`;

export const TokenName = styled(Title2)`
  color: ${({ theme }) => theme.white};
`;

export const FullName = styled(Caption12)`
  color: ${({ theme }) => theme.gray9};
  margin-top: 5px;
`;

export const Number = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.gray9};
`;
