import { useState } from "react";
import {
  HighlightBtn,
  Modal,
  BaseInputs,
  Help,
  SwitchBtn,
} from "../../components";
import {
  TitleContainer,
  TitleLabel,
  InputContainers,
  MinutesInput,
  MinutesInputContain,
  MinuteInputText,
  SwitchContainer,
  HeaderTitle,
  HeaderContainer,
} from "./style";

export const SettingsModal = ({ closeModal }) => {
  const [spillageTolerance, setSpillageTolerance] = useState("0.5%");
  const [currentId, setCurrentId] = useState(1);

  const spillageBtn = [
    {
      value: "0.1%",
    },
    {
      value: "0.5%",
    },
    {
      value: "1.0%",
    },
  ];

  const handleClick = (i, val) => {
    setCurrentId(i);
    setSpillageTolerance(val);
  };

  return (
    <Modal closeModal={closeModal}>
      <HeaderContainer>
        <HeaderTitle>Transaction Settings</HeaderTitle>
        <img onClick={closeModal} src={"/assets/svg/close.svg"} alt="" />
      </HeaderContainer>
      <TitleContainer>
        <TitleLabel>Slippage tolerance</TitleLabel>
        <Help
          message={
            "Your transaction will revert if the price changes unfavorably by more than this percentage."
          }
        />
      </TitleContainer>
      <InputContainers>
        {spillageBtn.map((btn, i) => (
          <main key={i}>
            <HighlightBtn
              active={currentId === i ? true : false}
              label={btn.value}
              onClick={() => handleClick(i, btn.value)}
            />
          </main>
        ))}
        <main>
          <BaseInputs value={spillageTolerance} />
        </main>
      </InputContainers>

      <TitleContainer>
        <TitleLabel>Transaction deadline</TitleLabel>
        <Help
          message={
            "Your transaction will revert if it is pending for more than this long."
          }
        />
      </TitleContainer>
      <MinutesInput>
        <MinutesInputContain>
          <BaseInputs value={"20"} />
        </MinutesInputContain>
        <MinuteInputText>Minutes</MinuteInputText>
      </MinutesInput>

      <HeaderContainer>
        <HeaderTitle>Interface Settings</HeaderTitle>
      </HeaderContainer>

      <SwitchContainer>
        <TitleContainer>
          <TitleLabel>Transaction deadline</TitleLabel>
          <Help
            message={
              "By passes confirmation modals and allows high slippage trades. Use at your own risk."
            }
          />
        </TitleContainer>
        <SwitchBtn />
      </SwitchContainer>

      <SwitchContainer>
        <TitleContainer>
          <TitleLabel>Disable Multihops</TitleLabel>
          <Help message={"Restricts swaps to direct pair only."} />
        </TitleContainer>
        <SwitchBtn />
      </SwitchContainer>
    </Modal>
  );
};
