export const lightTheme = {
  white: "#242424",
  black: "#ffffff",

  primary5: "#febf32",

  blue5: "#5F97FF",

  gray9: "#abafc4",
  gray12: "#888daa",
  gray18: "#4c516b",
  gray19: "#44485f",
  gray22: "#2a2d32",
  gray23: "#222531",
  gray24: "#F7F9FA",

  gradient8:
    "#242424",

  mask: "rgba(0, 0, 0, 0.4)",
  transparentBlue: "rgba(95, 151, 255, 0.15)",
  transparentGreen: "rgba(118, 226, 104, 0.15)",
};

export const darkTheme = {
  white: "#ffffff",
  black: "#000000",

  primary5: "#febf32",

  blue5: "#5F97FF",

  gray9: "#abafc4",
  gray12: "#888daa",
  gray18: "#4c516b",
  gray19: "#44485f",
  gray22: "#2a2d32",
  gray23: "#222531",
  gray24: "#17171A",

  gradient8:
    "linear-gradient(91.26deg, #70A2FF 0%, #72F6D1 21.87%, #76E268 47.92%, #FFD505 72.4%, #F76E64 100%)",

  mask: "rgba(0, 0, 0, 0.4)",
  transparentBlue: "rgba(95, 151, 255, 0.15)",
  transparentGreen: "rgba(118, 226, 104, 0.15)",
};
