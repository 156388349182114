import styled from "styled-components/macro";
import { lightTheme } from "../../theme";
import { H3Thin, ParagraphRegular } from "../../typography";

export const Container = styled.div`
  width: 60%;
  margin: 0px auto;

  @media (max-width: 1000px) {
    width: 98%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled(H3Thin)`
  background: ${({ theme }) => theme.gradient8};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Spacer = styled.div`
  width: 32px;
`;

export const InfoDescription = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  img {
    margin-left: 8px;
  }
`;

export const InfoDescriptionText = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.white};
`;

export const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  flex-direction: column;

  img {
    filter: ${({ theme }) => theme === lightTheme && "invert(100%)"};
  }
`;

export const EmptyStateInfo = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.gray9};
  margin-top: 15px;
  text-align: center;
  margin-bottom: 64px;
`;
