import styled from "styled-components/macro";
import { Caption12, ParagraphSemiBold } from "../../typography";

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.gray22};
  padding: 21px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContainer = styled.div``;

export const Destination = styled(ParagraphSemiBold)`
  color: ${({ theme }) => theme.white};
`;

export const Amount = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  width: 50%;

  &:not(:placeholder-shown) {
    background: ${({ theme }) => theme.gradient8};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.13rem;
    font-weight: 300;
  }

  font-size: 2.13rem;
  font-weight: 300;

  ::placeholder {
    font-size: 2.13rem;
    font-weight: 300;
    color: ${({ theme }) => theme.gray12};
  }
`;

export const Balance = styled(Caption12)`
  color: ${({ theme }) => theme.white};
  margin-top: 0px;
`;
