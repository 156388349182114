import { useState } from "react";
import { LiquidityCard, LiquidityDefaultState, SettingsModal } from "../../container";
import { Container } from "../swap/style";

export const Liquidity = () => {
  const [switchState, setSwitchState] = useState(false);
  const [settingModal, setSettingModal] = useState(false);

  const handleSetSwitchState = () => {
    setSwitchState(true);
  };

  const returnToPrevState = () => {
    setSwitchState(false);
  };

  const openSettingsModal = () => {
    setSettingModal(true);
  };

  const closeSettingsModal = () => {
    setSettingModal(false);
  };

  return (
    <>
      {settingModal ? <SettingsModal closeModal={closeSettingsModal} /> : null}

      <Container>
        {switchState ? (
          <LiquidityCard
            returnToPrevState={returnToPrevState}
            openSettingsModal={openSettingsModal}
          />
        ) : (
          <LiquidityDefaultState handleSetSwitchState={handleSetSwitchState} />
        )}
      </Container>
    </>
  );
};
