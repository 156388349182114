import { useState } from "react";
import { ThemeProvider } from "styled-components";
import AppRouter from "./appRouter";
import { GlobalStyles } from "./globalStyles";
import { lightTheme, darkTheme } from "./theme";

export default function App() {
  const [theme, setTheme] = useState("dark");
  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <AppRouter themeToggler={themeToggler} />
      </>
    </ThemeProvider>
  );
}
