import { useEffect, useState } from "react";
import { Button, ConnectModal, InputToken } from "../../components";
import {
  BtnContainer,
  Container,
  Body,
  Header,
  SwitchBtn,
  TransactionInfo,
  Texts,
  HeaderTitle
} from "./style";

export const SwapCard = ({ openSettingsModal }) => {
  const tokens = [
    {
      name: "ADA",
      fullName: "Cardano",
      icon: "",
      val: 0.3333,
    },

    {
      name: "Bash",
      fullName: "BashSwap",
      icon: "",
      val: 3,
    },
  ];

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [currentFromVal, setCurrentFromVal] = useState(tokens[0]);
  const [currentToVal, setCurrentToVal] = useState("");

  const [isValid, setIsValid] = useState(false);

  const [openConnectModal, setConnectModal] = useState(false);

  const handleCurrentFromValue = (token) => {
    setCurrentFromVal(token);
  };

  const handleCurrentToValue = (token) => {
    setCurrentToVal(token);
  };

  const switchTokens = () => {
    setFrom(to);
    setTo(from);
    setCurrentFromVal(currentToVal);
    setCurrentToVal(currentFromVal);
  };

  const handleChange = (e) => {
    setFrom(e.target.value);
    setTo(e.target.value);
  };

  useEffect(() => {
    if ([from, to, currentToVal].includes("")) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [from, to, currentToVal]);

  return (
    <>
      {openConnectModal ? (
        <ConnectModal closeModal={() => setConnectModal(false)} />
      ) : null}
      <Container>
        <Header>
          <HeaderTitle>Swap</HeaderTitle>
          <img
            onClick={openSettingsModal}
            src={"/assets/svg/setting.svg"}
            alt=""
          />
        </Header>
        <Body>
          <InputToken
            tokens={tokens}
            destination={"From"}
            amount={from}
            value={currentFromVal?.name}
            handleSelect={handleCurrentFromValue}
            onChange={handleChange}
          />
          <SwitchBtn onClick={switchTokens}>
            <img src={"/assets/svg/swap.svg"} alt={""} />
          </SwitchBtn>

          <InputToken
            tokens={tokens}
            destination={"To"}
            amount={currentToVal === "" ? "" : to * currentToVal?.val}
            value={currentToVal?.name}
            handleSelect={handleCurrentToValue}
          />
          {!isValid ? (
            <TransactionInfo>
              <Texts>Spillage Tolerance: 1%</Texts>
              <Texts>
                1 {currentFromVal?.name} = {currentToVal?.val}{" "}
                {currentToVal?.name}
              </Texts>
            </TransactionInfo>
          ) : null}
          <BtnContainer>
            <Button
              onClick={() => setConnectModal(true)}
              btnType={"big_btn"}
              value={isValid ? "Enter an Amount" : "Swap"}
              disabled={isValid}
            />
          </BtnContainer>
        </Body>
      </Container>
    </>
  );
};
