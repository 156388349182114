import * as ROUTES from "./constants/routes";
import { Swap, Liquidity } from "./pages";

export const routeBuilder = [
  {
    name: "swap",
    route: ROUTES.SWAP,
    components: <Swap />,
    layout: "user",
  },
  {
    name: "liquidity",
    route: ROUTES.LIQUIDITY,
    components: <Liquidity />,
    layout: "user",
  },
];
