import styled from "styled-components/macro";
import { Title1 } from "../../typography";
import { lightTheme, darkTheme } from "../../theme";

export const Container = styled.nav`
  width: calc(100%- 122px);
  height: 72px;
  padding: 0px 61px;
  background-color: ${({ theme }) => theme.gray24};

  @media (max-width: 1000px) {
    padding: 0px 20px;
  }
`;

export const ImageLogoDark = styled.span`
  display: ${({ theme }) => (theme === darkTheme ? "block" : "none")};
  width: 200px;
  margin-top: 10px;
  
  img {
    width: 100%;
  }
`;

export const ImageLogoLight = styled.span`
  display: ${({ theme }) => (theme === lightTheme ? "block" : "none")};
  width: 200px;
  margin-top: 10px;

  img {
    width: 100%;
  }
`;

export const InnerContainer = styled.div`
  max-width: 1440px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  height: 72px;

  main {
    margin: 0px 8px;
    &:first-of-type {
      margin-left: 0px;
    }
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const NavLinks = styled.div`
  height: 72px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }

  &:first-of-type {
    margin-left: 100px;
  }

  :after {
    display: ${({ active }) => (active ? "block" : "none")};
    content: "";
    position: absolute;
    bottom: 0;
    background-color: ${({ theme }) => theme.primary5};
    width: 100%;
    height: 2px;
    border: 2px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const NavText = styled(Title1)`
  color: ${({ active, theme }) => (active ? theme.primary5 : theme.gray9)};
`;

export const MobileBtnContainer = styled.div`
  width: calc(100% - 40px);
  border-top: 1px solid ${({ theme }) => theme.gray22};
  padding: 10px 20px;
  position: fixed;
  display: none;
  align-items: center;
  bottom: 0;
  z-index: 2;
  justify-content: space-between;

  @media (max-width: 1000px) {
    display: flex;
  }
`;
