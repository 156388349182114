import styled from "styled-components";
import { ParagraphRegular } from "../../typography";

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 75px;
  height: 40px;
  background: ${({ theme, active }) =>
    active ? theme.primary5 : theme.gray23};
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  :hover {
    opacity: 0.6;
  }
`;

export const Label = styled(ParagraphRegular)`
  color: ${({ theme, active }) => (active ? theme.black : theme.primary5)};
`;
