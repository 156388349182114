import {
  PrimaryContainer,
  SecondaryContainer,
  BigBtnContainer,
  IconBtn,
} from "./style";
import { Button16 } from "../../typography";

export const Button = ({ btnType, value, ...restProps }) => {
  // primary
  // secondary
  // big-btn
  //icon-btn

  if (btnType === "primary") {
    return (
      <PrimaryContainer {...restProps}>
        <Button16>{value}</Button16>
      </PrimaryContainer>
    );
  } else if (btnType === "secondary") {
    return (
      <SecondaryContainer {...restProps}>
        <Button16>{value}</Button16>
      </SecondaryContainer>
    );
  } else if (btnType === "big_btn") {
    return (
      <BigBtnContainer {...restProps}>
        <Button16>{value}</Button16>
      </BigBtnContainer>
    );
  } else if (btnType === "icon_btn") {
    return (
      <IconBtn {...restProps}>
        <img src={value} alt="" />
      </IconBtn>
    );
  } else {
    return <button>Enter valid button type</button>;
  }
};
