import styled from "styled-components";

export const InputContainer = styled.input`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.gray23};
  border-radius: 8px;
  padding: 8px 16px;
  outline: none;
  color: ${({ theme }) => theme.gray18};
  font-size: 0.88rem;
  width: calc(100% - 32px);
`;
