import {
  BtnContainer,
  Container,
  Header,
  HeaderTitle,
  Spacer,
  InfoDescription,
  InfoDescriptionText,
  EmptyState,
  EmptyStateInfo,
} from "./style";
import { Button, FooterLink, SearchBar } from "../../components";

export const LiquidityDefaultState = ({ handleSetSwitchState }) => {
  return (
    <Container>
      <Header>
        <HeaderTitle>Your Liquidity</HeaderTitle>
        <BtnContainer>
          <SearchBar placeholder={"Search from liquidity..."} />
          <Spacer/>
          <Button
            btnType={"primary"}
            onClick={handleSetSwitchState}
            value={"Add liquidity"}
          />
        </BtnContainer>
      </Header>
      <InfoDescription>
        <InfoDescriptionText>Liquidity provider rewards</InfoDescriptionText>
        <img src={"/assets/svg/info.svg"} alt="" />
      </InfoDescription>

      <EmptyState>
        <img src={"/assets/svg/archieve.svg"} alt="" />
        <EmptyStateInfo>No liquidity found.</EmptyStateInfo>
        <FooterLink
          question={"Don't see a pool you joined"}
          link={"Import it."}
        />
      </EmptyState>
    </Container>
  );
};
