import styled from "styled-components/macro";
import { lightTheme } from "../../theme";
import { Caption12 } from "../../typography";

export const Info = styled.div`
  display: none;
  position: absolute;
  min-width: 179px;
  max-width: calc(179px - 16px);
  background-color: ${({ theme }) => theme.gray22};
  border-radius: 8px;
  padding: 8px;
  margin-left: 10px;
`;

export const InfoText = styled(Caption12)`
  font-weight: 400 !important;
  color: ${({ theme }) => (theme === lightTheme ? theme.black : theme.white)};
  line-height: 16px;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  width: fit-content;

  &:hover ${Info} {
    display: block;
  }
`;

export const InfoBtn = styled.img`
  filter: ${({ theme }) => theme === lightTheme && "invert(100%)"};
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
