import { useState } from "react";
import { SettingsModal, SwapCard } from "../../container";
import { Container } from "./style";

export const Swap = () => {
  const [settingModal, setSettingModal] = useState(false);

  const openSettingsModal = () => {
    setSettingModal(true);
  };

  const closeSettingsModal = () => {
    setSettingModal(false);
  };

  return (
    <Container>
      {settingModal ? <SettingsModal closeModal={closeSettingsModal} /> : null}
      <SwapCard openSettingsModal={openSettingsModal} />
    </Container>
  );
};
