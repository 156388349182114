import styled from "styled-components/macro";

export const H1Bold = styled.h1`
  font-size: 6rem;
  font-weight: 600;
`;

export const H2Bold = styled.h2`
  font-size: 3.75rem;
  font-weight: 600;
`;

export const H3Bold = styled.h3`
  font-size: 3rem;
  font-weight: 600;
`;

export const H4Bold = styled.h4`
  font-size: 2.13rem;
  font-weight: 600;
`;

export const H5Bold = styled.h5`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const H6Bold = styled.h6`
  font-size: 1.3rem;
  font-weight: 600;
`;

export const H1Thin = styled.h1`
  font-size: 6rem;
  font-weight: 300;
`;

export const H2Thin = styled.h2`
  font-size: 3.75rem;
  font-weight: 300;
`;

export const H3Thin = styled.h3`
  font-size: 3rem;
  font-weight: 300;
`;

export const H4Thin = styled.h4`
  font-size: 2.13rem;
  font-weight: 300;
`;

export const H5Thin = styled.h5`
  font-size: 1.5rem;
  font-weight: 300;
`;

export const H6Thin = styled.h6`
  font-size: 1.3rem;
  font-weight: 300;
`;

export const Title1 = styled.p`
  font-size: 1.13rem;
  font-weight: 600;
`;

export const Title2 = styled.p`
  font-size: 1rem;
  font-weight: 600;
`;

export const SubTitle1 = styled.p`
  font-size: 1.13rem;
  font-weight: 400;
`;

export const SubTitle2 = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

export const ParagraphSemiBold = styled.p`
  font-size: 0.88rem;
  font-weight: 600;
`;

export const ParagraphRegular = styled.p`
  font-size: 0.88rem;
  font-weight: 400;
`;

export const Button12 = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
`;

export const Button14 = styled.p`
  font-size: 0.88rem;
  font-weight: 600;
`;

export const Button16 = styled.p`
  font-size: 1rem;
  font-weight: 600;
`;

export const Caption12 = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
`;

export const Caption13 = styled.p`
  font-size: 0.88rem;
  font-weight: 700;
`;