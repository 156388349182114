import { Button } from "../../components";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  InnerContainer,
  BtnContainer,
  Spacer,
  NavLinks,
  NavText,
  MobileBtnContainer,
  ImageLogoDark,
  ImageLogoLight,
} from "./style";
import { LIQUIDITY, SWAP } from "../../constants/routes";

export const Navigation = ({ connectToWallet, themeToggler }) => {
  const navLinks = [
    {
      title: "Swap",
      route: SWAP,
      id: 0,
    },
    {
      title: "liquidity",
      route: LIQUIDITY,
      id: 1,
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const buttonGroup = [
    {
      value: "0 Bash",
      type: "primary",
      onClick: null,
    },
    {
      value: "Connect Wallet",
      type: "secondary",
      onClick: connectToWallet,
    },
    {
      value: "/assets/svg/sun.svg",
      type: "icon_btn",
      onClick: themeToggler,
    },
    {
      value: "/assets/svg/more.svg",
      type: "icon_btn",
      onClick: null,
    },
  ];

  const handleCurrentId = (route) => {
    navigate(route);
  };

  return (
    <>
      <Container>
        <InnerContainer>
          <ImageLogoDark>
            <img src={"/assets/img/logo.png"} alt={""} />
          </ImageLogoDark>
          <ImageLogoLight>
            <img src={"/assets/img/lighLogo.png"} alt={""} />
          </ImageLogoLight>
          {navLinks.map((link) => (
            <NavLinks
              key={link.id}
              onClick={() => handleCurrentId(link.route)}
              active={location.pathname === link.route ? true : false}
            >
              <NavText active={location.pathname === link.route ? true : false}>
                {link.title}
              </NavText>
            </NavLinks>
          ))}
          <Spacer />
          <BtnContainer>
            {buttonGroup.map((btn, i) => (
              <main key={i}>
                <Button
                  btnType={btn.type}
                  value={btn.value}
                  onClick={btn.onClick}
                />
              </main>
            ))}
          </BtnContainer>
        </InnerContainer>
      </Container>

      <MobileBtnContainer>
        {buttonGroup.map((btn, i) => (
          <main key={i}>
            <Button btnType={btn.type} value={btn.value} />
          </main>
        ))}
      </MobileBtnContainer>
    </>
  );
};
