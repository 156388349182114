import styled from "styled-components/macro";
import { lightTheme } from "../../theme";
import { H3Thin, ParagraphRegular } from "../../typography";

export const Container = styled.div`
  max-width: 514px;
  margin: 0px auto;
  @media (max-width: 520px) {
    width: 98%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    filter: ${({ theme }) => theme === lightTheme && "invert(100%)"};
    cursor: pointer;
    transition: 0.2s ease-in-out;

    :hover {
      opacity: 0.6;
    }
  }
`;

export const HeaderTitle = styled(H3Thin)`
  background: ${({ theme }) => theme.gradient8};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const HeaderText = styled(H3Thin)`
  color: ${({ theme }) => theme.gradient8};
`;

export const Body = styled.div`
  margin-top: 41px;
`;

export const SwitchBtn = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    cursor: pointer;
    transition: 0.2s ease-in-out;

    :hover {
      opacity: 0.6;
    }
  }
`;

export const BtnContainer = styled.div`
  margin-top: 38px;
`;

export const TransactionInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;

export const Texts = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.white};
`;
