import styled from "styled-components";

export const InputBox = styled.input`
  width: calc(100% - 32px);
  padding: 0px 16px;
  height: 64px;
  border: 1px solid ${({ theme }) => theme.gray23};
  border-radius: 8px;
  outline: none;
  transition: 0.2s ease-in-out;
  background: transparent;
  color: ${({ theme }) => theme.white};
  font-size: 0.88rem;

  ::placeholder {
    color: ${({ theme }) => theme.gray12};
    font-size: 0.88rem;
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.white};
  }
`;
