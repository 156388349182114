import { useState } from "react";
import Switch from "react-switch";

export const SwitchBtn = () => {
  const [checked, setChecked] = useState(true);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  return (
    <Switch
      onChange={handleChange}
      checked={checked}
      uncheckedIcon={false}
      checkedIcon={false}
      onColor={"#febf32"}
      height={32}
      width={52}
      handleDiameter={24}
    />
  );
};
