import { FooterLink } from "../footerLink";
import {
  Tint,
  Container,
  Header,
  InfoContainer,
  FooterContainer,
  SelectOption,
  SelectOptionText,
  HeaderTitle
} from "./style";

export const ConnectModal = ({ closeModal }) => {
  const wallet = [
    {
      name: "Nami Wallet",
      img: "",
    },
    {
      name: "Coinbase Wallet",
      img: "",
    },
    {
      name: "Portis",
      img: "",
    },
    {
      name: "WalletConnect",
      img: "",
    },
  ];

  return (
    <>
      <Tint onClick={closeModal} />
      <Container>
        <Header>
          <HeaderTitle>Connect Wallet</HeaderTitle>
          <img onClick={closeModal} src={"/assets/svg/close.svg"} alt="" />
        </Header>
        <InfoContainer>
          {wallet.map((wallet, i) => (
            <SelectOption key={i}>
              <SelectOptionText>{wallet.name}</SelectOptionText>
              <img src={wallet.img || "/Assets/svg/wallet.svg"} alt="" />
            </SelectOption>
          ))}
          <FooterContainer>
            <FooterLink
              question={"New to Cardano"}
              link={"Learn more about wallets"}
            />
          </FooterContainer>
        </InfoContainer>
      </Container>
    </>
  );
};
