import styled from "styled-components/macro";
import { H5Thin, Title2 } from "../../typography";

export const Tint = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.mask};
  z-index: 3;
`;

export const Container = styled.div`
  width: 512px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;

  @media (max-width: 600px) {
    width: 98%;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 56px;
  position: relative;

  img {
    :last-of-type {
      cursor: pointer;
      transition: 0.3s ease-in-out;
      position: absolute;
      right: 0;

      :hover {
        opacity: 0.6;
      }
    }
  }
`;

export const HeaderTitle = styled(H5Thin)`
  background: ${({ theme }) => theme.gradient8};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const InfoContainer = styled.div`
  width: calc(100% - 48px);
  background-color: ${({ theme }) => theme.gray24};
  border-radius: 16px;
  max-height: 416px;
  min-height: 416px;
  overflow-y: scroll;
  padding: 0px 24px;
  padding-top: 116px;
  padding-bottom: 40px;
  position: relative;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.gray23};
    border-radius: 10px;
  }
`;

export const SelectOption = styled.div`
  padding: 16px;
  border: 1px solid transparent;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  cursor: pointer;

  &:first-of-type {
    margin-top: 0px;
  }

  :hover {
    border: 1px solid ${({ theme }) => theme.primary5};
  }
`;

export const SelectOptionText = styled(Title2)`
  color: ${({ theme }) => theme.white};
`;

export const FooterContainer = styled.div`
  width: 100%;
  height: 70px;
  position: fixed;
  bottom: 0;
`;
