import {
  Container,
  ValueContainer,
  Value,
  Spacer,
  ImgContainer,
  ModalHeaderText,
  ModalHeader,
  Option,
  InfoSection,
  TokenName,
  FullName,
  Number,
  List,
} from "./style";
import { Button14, ParagraphSemiBold } from "../../typography";
import { Modal } from "../modal";
import { useEffect, useState } from "react";
import { SearchBar } from "../searchBar";

export const TokenSelect = ({ value, tokens, handleSelect }) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setModal(false);
  }, [handleSelect]);

  return (
    <>
      {modal ? (
        <Modal closeModal={() => setModal(false)}>
          <ModalHeader>
            <ModalHeaderText>Select a Token</ModalHeaderText>
          </ModalHeader>
          <SearchBar placeholder={"Search name or paste address"} />
          <List>
            {tokens.map((token, i) => (
              <Option key={i} onClick={() => handleSelect(token)}>
                <InfoSection>
                  <ImgContainer />
                  <aside>
                    <TokenName>{token.name}</TokenName>
                    <FullName>{token.fullName}</FullName>
                  </aside>
                </InfoSection>
                <Number>{token.val}</Number>
              </Option>
            ))}
          </List>
        </Modal>
      ) : null}
      {value ? (
        <ValueContainer onClick={() => setModal(true)}>
          <ImgContainer />
          <Value>
            <ParagraphSemiBold>{value}</ParagraphSemiBold>
          </Value>
          <Spacer />
          <img src={"/assets/svg/chevronDown.svg"} alt="" />
        </ValueContainer>
      ) : (
        <Container onClick={() => setModal(true)}>
          <Button14>Select Token</Button14>
        </Container>
      )}
    </>
  );
};
