import { useState, useEffect } from "react";
import { Button, InputToken, ConnectModal } from "../../components";
import { Body, BtnContainer, Container, Header } from "../swapCard/style";
import {
  HeaderText,
  Message,
  MessageTitle,
  MessageMessage,
  PlusContainer,
  PoolShareInfo,
  PoolShareInfoHeader,
  PoolInfoContainer,
  PoolInfoValues,
  Footer,
} from "./style";

export const LiquidityCard = ({ openSettingsModal, returnToPrevState }) => {
  const tokens = [
    {
      name: "ADA",
      fullName: "Cardano",
      icon: "",
      val: 0.3333,
    },

    {
      name: "Bash",
      fullName: "BashoSwap",
      icon: "",
      val: 3,
    },
  ];

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [currentFromVal, setCurrentFromVal] = useState(tokens[0]);
  const [currentToVal, setCurrentToVal] = useState("");

  const [openConnectModal, setConnectModal] = useState(false);

  const [isValid, setIsValid] = useState(false);

  const handleCurrentToValue = (token) => {
    setCurrentToVal(token);
  };

  const handleCurrentFromValue = (token) => {
    setCurrentFromVal(token);
  };

  const handleChangeFrom = (e) => {
    setFrom(e.target.value);
  };

  const handleChangeTo = (e) => {
    setTo(e.target.value);
  };

  useEffect(() => {
    if ([from, to, currentToVal].includes("")) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [from, to, currentToVal]);

  return (
    <>
      {openConnectModal ? (
        <ConnectModal closeModal={() => setConnectModal(false)} />
      ) : null}
      <Container>
        <Header>
          <img
            onClick={returnToPrevState}
            src={"/assets/svg/chevronLeft.svg"}
            alt=""
          />
          <HeaderText>Create a Pair</HeaderText>
          <img
            onClick={openSettingsModal}
            src={"/assets/svg/setting.svg"}
            alt=""
          />
        </Header>
        <Message>
          <MessageTitle>You are the first liquidity provider</MessageTitle>
          <MessageMessage>
            The ratio of tokens you add will set the price. Once you are ok with
            the rate click supply to review.
          </MessageMessage>
        </Message>
        <Body>
          <InputToken
            tokens={tokens}
            destination={"From"}
            amount={from}
            value={currentFromVal?.name}
            handleSelect={handleCurrentFromValue}
            onChange={handleChangeFrom}
          />
          <PlusContainer>
            <img src={"/assets/svg/addition.svg"} alt="" />
          </PlusContainer>
          <InputToken
            tokens={tokens}
            destination={"To"}
            amount={to}
            value={currentToVal?.name}
            handleSelect={handleCurrentToValue}
            onChange={handleChangeTo}
          />
          {currentToVal !== "" && (
            <PoolShareInfo>
              <PoolShareInfoHeader>Prices and pool share</PoolShareInfoHeader>
              <PoolInfoContainer>
                <div>
                  <PoolShareInfoHeader>
                    {currentFromVal.val}
                  </PoolShareInfoHeader>
                  <PoolInfoValues>
                    {currentFromVal.name} per {currentToVal.name}
                  </PoolInfoValues>
                </div>

                <div>
                  <PoolShareInfoHeader>{currentToVal.val}</PoolShareInfoHeader>
                  <PoolInfoValues>
                    {currentToVal.name} per {currentFromVal.name}
                  </PoolInfoValues>
                </div>

                <div>
                  <PoolShareInfoHeader>0%</PoolShareInfoHeader>
                  <PoolInfoValues>Share of Pool</PoolInfoValues>
                </div>
              </PoolInfoContainer>
            </PoolShareInfo>
          )}
          <BtnContainer>
            <Button
              onClick={() => setConnectModal(true)}
              btnType={"big_btn"}
              value={isValid ? "Invalid Pair" : "Supply"}
              disabled={isValid}
            />
          </BtnContainer>
        </Body>
        <Footer />
      </Container>
    </>
  );
};
