import { InfoText, Info, Container, InfoBtn } from "./style";

export const Help = ({ message }) => {
  return (
    <Container>
      <InfoBtn src={"/assets/svg/question-help.svg"} alt={""} />
      <Info>
        <InfoText>{message}</InfoText>
      </Info>
    </Container>
  );
};
