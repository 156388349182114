import styled from "styled-components/macro";
import { ParagraphRegular } from "../../typography";

export const FooterText = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.white};

  span {
    color: ${({ theme }) => theme.blue5};
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;
    transition: 0.3s ease-in-out;

    :hover {
      opacity: 0.6;
    }
  }
`;
