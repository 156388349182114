import styled from "styled-components/macro";
import {
  Caption12,
  H5Thin,
  ParagraphRegular,
  ParagraphSemiBold,
  Title1,
} from "../../typography";

export const HeaderText = styled(H5Thin)`
  background: ${({ theme }) => theme.gradient8};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Message = styled.div`
  margin-top: 40px;
  width: calc(100% - 32px);
  padding: 16px;
  background-color: ${({ theme }) => theme.transparentBlue};
  border-radius: 8px;
`;

export const MessageTitle = styled(Title1)`
  color: ${({ theme }) => theme.blue5};
`;

export const MessageMessage = styled(ParagraphRegular)`
  color: ${({ theme }) => theme.blue5};
  margin-top: 16px;
  line-height: 24px;
`;

export const PlusContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PoolShareInfo = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const PoolShareInfoHeader = styled(ParagraphSemiBold)`
  color: ${({ theme }) => theme.white};
`;

export const PoolInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 40px;
  margin-top: 24px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const PoolInfoValues = styled(Caption12)`
  color: ${({ theme }) => theme.gray9};
  text-align: center;
  margin-top: 4px;
`;

export const Footer = styled.footer`
  height: 60px;
`;
